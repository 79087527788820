body {
  width: 100%;
  height: 100%;
  background-image: url("background.png");
  background-size: cover;
  background-position: center;
}

@font-face {
  font-family: "OFA_Font_Headline";
  src: url("font.otf");
}

@font-face {
  font-family: "OFA_Font_Text";
  src: url("Akrobat-Regular.otf");
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h4,
h3,
h2,
button,
p {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

button {
  width: 200px;
  height: 50px;
  color: black;
  background-color: #fedb00;
  font-size: 20px;
  font-family: "OFA_Font_Headline";
  text-transform: uppercase;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.yellow {
  color: #fedb00;
}

.center {
  margin: auto;
  width: 5%;
}

h1,
h2,
h3 {
  font-family: "OFA_Font_Headline";
  text-transform: uppercase;
}

p {
  font-family: "OFA_Font_Text";
}

.content {
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  width: 30vw;
  height: 70vh;
  color: white;

  padding: 30px;
  -webkit-box-shadow: 0px 0px 100px 10px rgba(254, 220, 0, 0.28);
  -moz-box-shadow: 0px 0px 100px 10px rgba(254, 220, 0, 0.28);
  box-shadow: 0px 0px 100px 10px rgba(254, 220, 0, 0.28);
}

@media only screen and (max-width: 600px) {
  .content {
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);
    width: 70vw;
    height: 60vh;
    color: white;

    padding: 30px;
    -webkit-box-shadow: 0px 0px 100px 10px rgba(254, 220, 0, 0.28);
    -moz-box-shadow: 0px 0px 100px 10px rgba(254, 220, 0, 0.28);
    box-shadow: 0px 0px 100px 10px rgba(254, 220, 0, 0.28);
  }
}
